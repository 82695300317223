import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';
import styled, { createGlobalStyle } from 'styled-components';
import { useLocalStorage } from './hooks';

const DEV = false;
const PATH = DEV ? "http://localhost:3000" : "https://email.6emme.it";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
  #demo {
    height: 100%;
  }
  #editor-3{
    height: calc(100vh - 40px);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #eee;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;
  justify-content: space-between;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }

  button {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
  }
`;

const App = (props) => {
    const emailEditorRef = useRef(null);
    console.log(emailEditorRef);
    const [design, setDesign] = useLocalStorage('design', '');

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            navigator.clipboard.writeText(html);
            alert('HTML copiato! Incollalo dove vuoi!');
        });
    };

    const saveDesign = (copy = true) => {
        emailEditorRef.current.editor.saveDesign((design) => {
            setDesign(JSON.stringify(design))
            if (!copy)
                return;

            navigator.clipboard.writeText(JSON.stringify(design))
            alert('JSON del design copiato. Ricordati di salvarlo da qualche parte!')
        })
    }

    const loadDesign = () => {
        try {
            const response = prompt("Inserisci il codice del design copiato")
            emailEditorRef.current.editor.loadDesign(JSON.parse(response))
        } catch (error) {
            alert('Si è verificato un errore con il codice inserito');
            console.log(error);
        }
    }

    const onLoad = (unlayer) => {
        // editor instance is created
        // you can load your template here;
        if (!design)
            return;

        emailEditorRef.current.editor.loadDesign(JSON.parse(design));
    }

    const onReady = () => {
        // editor is ready
        emailEditorRef.current.editor.addEventListener('design:updated', function (e) {
            saveDesign(false)
        })
    };

    return (
        <Container>
            <GlobalStyle />
            <Bar>
                <img src="https://6emme.it/wp-content/uploads/2020/06/logo_6emme-250x46.png" alt="" />
                <div>
                    <button onClick={exportHtml}>Export HTML</button>
                    <button onClick={saveDesign}>Save Design</button>
                    <button onClick={loadDesign}>Load Design</button>
                </div>
            </Bar>
            <div>
                <EmailEditor
                    options={{
                        customJS: "https://email.6emme.it/tools/custom.js"
                    }}
                    projectId={225753}
                    minHeight={'calc(100vh - 60px)'}
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    onReady={onReady}
                />
            </div>
        </Container>
    );
};

export default App